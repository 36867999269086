import _ from 'lodash';
import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import {
  Category,
  Request as AuthorizeRequest,
  Response as AuthorizeResponse,
  Sandbox,
} from '@finch-api/common/dist/internal/connect/authorize';
import {
  Request as ValidateRequest,
  Response as ValidateResponse,
  RESPONSE_SCHEMA as VALIDATE_RESPONSE_SCHEMA,
} from '@finch-api/common/dist/internal/connect/validate';

import { EMPLOYER_PREFIX, productsConfig } from 'constants/products';
import {
  paramClientId,
  paramProducts,
  paramRedirectUri,
} from 'constants/params';
import { getBackendUrl } from './get-backend-url';

const BACKEND_ROUTE = getBackendUrl();

const _validateClientId = (clientId: string) => {
  if (!clientId)
    throw new Error(`${paramClientId} query parameter is required`);
};

const _validateRedirectUri = (redirectUri: string) => {
  if (!redirectUri)
    throw new Error(`${paramRedirectUri} query parameter is required`);
};

const _validateProducts = (products: string[], category: Category) => {
  if (!products || _.isEmpty(products))
    throw new Error(`${paramProducts} query parameter is required`);

  const validProducts = Object.keys(productsConfig[category]);

  _.forEach(products, (product) => {
    if (!_.includes(validProducts, product))
      throw new Error(`${product} is an invalid product`);
  });
};

export const validateAppType = (type: string) => {
  if (!['ssr', 'spa'].includes(type))
    throw new Error(`invalid application type - ${type}`);
};

export const validateCategory = (category: Category) => {
  if (![Category.HRIS].includes(category))
    throw new Error(`invalid category - ${category}`);
};

const _transformProduct = (
  products: string[] | undefined,
  category: Category,
): string[] =>
  (products || []).map((product: string): string =>
    category === Category.HRIS ? `${EMPLOYER_PREFIX}${product}` : product,
  );

const _transformDomain = (domain: string | undefined) =>
  domain ? _.split(domain, '.')[0] : undefined;

/**
 * Wrapper for invoking API server POST /auth/validate endpoint which will
 * validate the initial Finch Connect parameters.
 */
export const validate = async ({
  clientId,
  connectionId,
  redirectUri,
  products,
  category,
  sessionKey,
  currentBrowserSessionKey,
  manual,
  sandbox,
  providerId,
  clientName,
}: {
  clientId: string;
  redirectUri: string;
  products: string[];
  category: Category;
  sessionKey: string;
  currentBrowserSessionKey: string;
  manual: boolean;
  sandbox: Sandbox;
  connectionId?: string;
  providerId: string;
  clientName?: string;
}): Promise<ValidateResponse> => {
  _validateClientId(clientId);
  _validateRedirectUri(redirectUri);
  _validateProducts(products, category);

  const requestBody: ValidateRequest = {
    clientId,
    redirectUri,
    products: _transformProduct(products, category),
    category,
    sessionKey,
    currentBrowserSessionKey,
    manual,
    sandbox,
    providerId: providerId.length ? providerId : undefined,
    clientName,
    connectionId,
  };

  try {
    const response = await axios.post(
      `${BACKEND_ROUTE}/auth/validate`,
      requestBody,
    );

    const bodyParseResult = VALIDATE_RESPONSE_SCHEMA.safeParse(response.data);
    if (!bodyParseResult.success) {
      const msg = `zod: /auth/validate response validation failed: ${bodyParseResult.error.message}`;
      Sentry.captureMessage(msg);
      console.warn(msg);
    }

    // TODO: @aren55555 @M-Shehu remove the cast to ValidateResponse if the
    // above Sentry does not trigger
    return response.data as ValidateResponse;
  } catch (err) {
    throw new Error(
      _.get(
        err,
        'response.data.message',
        'An unexpected error occurred, try again!',
      ),
    );
  }
};

export const validateSessionId = async (opts: {
  sessionId: string;
  currentBrowserSessionKey: string;
  redirectUri?: string;
  products?: string[];
}) => {
  const { sessionId, products, redirectUri, currentBrowserSessionKey } = opts;
  try {
    const response = await axios.post<ValidateResponse>(
      `${BACKEND_ROUTE}/auth/validate`,
      { sessionId, products, redirectUri, currentBrowserSessionKey },
    );

    return response.data;
  } catch (err) {
    throw new Error(
      (err as AxiosError).response?.data?.message ||
        'An unexpected error occurred, try again!',
    );
  }
};

/**
 * Wrapper for invoking API server POST /auth/authorize endpoint which will
 * attempt to sign in via the chosen adapter.
 */
export const authorize = async ({
  clientId,
  clientName,
  connectionId,
  redirectUri,
  products = [],
  username,
  password,
  providerClientId,
  providerClientSecret,
  providerRedirectUri,
  companyId,
  clientCode,
  domain,
  fullDomain,
  reportUrl,
  tenantId,
  apiBaseUrl,
  apiToken,
  SID,
  userAccessKey,
  mfaCode,
  chosenAccount,
  sessionKey,
  payrollProviderId,
  providerImplementationId,
  state,
  category,
  implementationHasAssistedBenefits,
  step = 'SIGN_IN',
  contactEmail,
  companyName,
  sandbox = false,
  isManualAuth = false,
  sdkVersion,
  appType,
  acfFallbackEligible,
  codeChallenge,
  codeChallengeMethod,
  codeVerifier,
  oauthRedirectUri,
  currentBrowserSessionKey,
}: {
  clientId: string;
  clientName?: string; // client name aliasing
  connectionId?: string;
  currentBrowserSessionKey: string;
  redirectUri: string;
  sessionKey: string;
  appType: 'ssr' | 'spa';
  category: Category;
  products?: string[];
  username?: string;
  password?: string;
  providerClientId?: string;
  providerClientSecret?: string;
  providerRedirectUri?: string;
  companyId?: string;
  clientCode?: string;
  domain?: string;
  fullDomain?: string;
  reportUrl?: string;
  tenantId?: string;
  apiBaseUrl?: string;
  apiToken?: string;
  SID?: string;
  userAccessKey?: string;
  mfaCode?: string;
  chosenAccount?: any;
  payrollProviderId?: string;
  providerImplementationId: string;
  state?: string;
  step?: string;
  contactEmail?: string;
  companyName?: string;
  sandbox?: Sandbox;
  implementationHasAssistedBenefits: boolean;
  isManualAuth?: boolean;
  sdkVersion?: string;
  acfFallbackEligible?: boolean;
  codeChallenge?: string;
  codeChallengeMethod?: string;
  codeVerifier?: string;
  oauthRedirectUri?: string;
}): Promise<AuthorizeResponse> => {
  const requestBody: AuthorizeRequest = {
    clientId,
    clientName,
    connectionId,
    redirectUri,
    products: _transformProduct(
      sandbox === 'provider' && implementationHasAssistedBenefits
        ? products.filter((product) => product !== 'benefits')
        : products,
      category,
    ),
    username,
    password,
    currentBrowserSessionKey,
    providerClientId,
    providerClientSecret,
    providerRedirectUri,
    companyId,
    clientCode,
    domain: _transformDomain(domain),
    fullDomain,
    reportUrl,
    tenantId,
    apiBaseUrl,
    apiToken,
    SID,
    userAccessKey,
    mfaCode,
    chosenAccount,
    sessionKey,
    step,
    state,
    category,
    payrollProviderId: payrollProviderId || '',
    providerImplementationId,
    contactEmail,
    companyName,
    sandbox,
    isManualAuth,
    sdkVersion,
    appType,
    acfFallbackEligible,
    codeChallenge,
    codeChallengeMethod,
    codeVerifier,
    // @ts-ignore
    oauthRedirectUri,
  };

  try {
    return (
      await axios.post<AuthorizeResponse>(
        `${BACKEND_ROUTE}/auth/authorize`,
        requestBody,
      )
    ).data;
  } catch (err: any) {
    err.message = _.get(
      err,
      'response.data.message',
      'An unexpected error occurred, try again!',
    );
    throw err;
  }
};
