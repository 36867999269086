import React, { useState } from 'react';
import _ from 'lodash';
import { authorize } from 'services/auth';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import payrollProviderToLocalLogosMap from 'constants/payrollProviderToLocalLogosMap';
import Container from 'components/Container/Container';
import Header from 'components/Header/Header';
import Input from 'components/Input/Input';
import LoaderButton from 'components/Button/LoaderButton';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import ProviderLogo from 'components/Logo/ProviderLogo';
import MainContainer from 'components/Container/MainContainer';
import { AuthProcessingResponse } from '@finch-api/common/dist/internal/connect/authorize';
import WarningMessage from '../Messages/Warning';
import styled from 'styled-components';
import { GoBackButton } from 'components/Button/GoBackButton';
import SignInDisclaimer from 'components/Text/SignInDisclaimer';
import AuthFallbackBoundary, { useAuthFailureCount } from '../AuthFallbackBoundary';
import { getStatusCode } from '../SignIn/utils';
const MfaText = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
  width: 100%;
`;
const Mfa = ({
  client,
  setUsingAcfFallback,
  handleStateRedirect,
  clientId,
  connectionId,
  clientName,
  redirectUri,
  products,
  sessionKey,
  currentBrowserSessionKey,
  state,
  category,
  payrollProvider,
  providerImplementation,
  prevStep,
  phoneNumber,
  email,
  mfaType,
  challengeQuestion,
  handleAuthorizeResponse,
  sandbox,
  appType,
  setError,
  setProviderToRedirect,
  mfaReason,
  implementationHasAssistedBenefits
}: AuthorizeContextValue) => {
  const {
    authFailureCounts,
    setAuthFailureCounts
  } = useAuthFailureCount();
  if (!client) throw new Error('no client');
  if (!payrollProvider) throw new Error('no payroll provider');
  if (!providerImplementation) throw new Error('no provider implementation');
  const [loading, setLoading] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  // The form key is used to reset the form when the user submits and the api-server
  // returns a next of MFA. This will cause the form to re-render and clear the input
  // field for the next MFA page.
  const [formKey, setFormKey] = useState(0);
  const onContinue = async (fieldToValueMap: Record<string, string>) => {
    setLoading(true);
    setError(null);
    const {
      mfaCode
    } = fieldToValueMap;
    try {
      const authorizeResponse = (await authorize({
        clientId,
        connectionId,
        clientName,
        redirectUri,
        products,
        mfaCode,
        sessionKey,
        currentBrowserSessionKey,
        state,
        category,
        step: 'MFA',
        payrollProviderId: payrollProvider.id,
        providerImplementationId: providerImplementation.id,
        sandbox,
        appType,
        implementationHasAssistedBenefits
      })) as AuthProcessingResponse;
      if (authorizeResponse.next === 'MFA') {
        setFormKey(formKey + 1);
      }
      handleAuthorizeResponse(authorizeResponse);

      // If we are redirecting, we keep the loading state to avoid double submission.
      // Therefore, we only remove the loading state when there is no redirect.
      if (!('redirect' in authorizeResponse)) {
        setLoading(false);
      }
    } catch (err: any) {
      setLoading(false);
      const finchCode = _.get(err, 'response.data.finch_code');
      if (['account_setup_required', 'no_valid_accounts', 'session_expired', 'incorrect_payroll_provider'].includes(finchCode)) {
        setDisableSubmit(true);
      }
      if (finchCode === 'incorrect_payroll_provider') {
        const correctPayrollProvider = _.get(err, 'response.data.correct_payroll_provider');
        setProviderToRedirect(correctPayrollProvider);
      }
      if (err.message === 'Incorrect MFA code. Max attempts reached. Please try again from the beginning.') {
        setError({
          message: err.message
        });
        prevStep();
      }
      setError({
        message: err.message || 'Unexpected error has occurred. Please try again.'
      });
      const errStatusCode = getStatusCode(err);
      if (500 <= errStatusCode && errStatusCode < 600) {
        const failures = authFailureCounts[providerImplementation.id] ?? 0;
        setAuthFailureCounts({
          ...authFailureCounts,
          [providerImplementation.id]: failures + 1
        });
      }
    }
  };
  const getMfaText = () => {
    switch (mfaType) {
      case 'text_message':
      case 'voice':
        {
          if (phoneNumber) return `Enter the code sent to ${phoneNumber}`;
          return 'Enter the code sent to your phone';
        }
      case 'authenticator_app':
        return 'Enter the code from the authenticator app';
      case 'email':
        {
          if (email) return `Enter the code sent to ${email}`;
          return 'Enter the code sent to your email';
        }
      case 'question':
        return challengeQuestion;
      default:
        return 'Enter the code sent to your phone';
    }
  };
  const getMfaReason = () => {
    switch (mfaReason) {
      case 'ADD_ACCOUNTANT':
        return `This secondary MFA is required to add an additional user to your account. `;
      case 'GET_API_TOKEN':
        return `A second MFA code is required to securely connect. Please wait for the new code to generate, which may take up to 30 seconds. Once received, enter the new code below.`;
      default:
        return null;
    }
  };
  const mfaText = getMfaText();
  const mfaReasonText = getMfaReason();
  const headerText = mfaReasonText ? 'Additional MFA Required' : mfaText;
  return <AuthFallbackBoundary client={client} provider={payrollProvider} implementationDetail={providerImplementation} setUsingAcfFallback={setUsingAcfFallback} handleStateRedirect={handleStateRedirect} setError={setError} data-sentry-element="AuthFallbackBoundary" data-sentry-component="Mfa" data-sentry-source-file="Mfa.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="Mfa.tsx">
        <GoBackButton onClick={() => prevStep()} data-sentry-element="GoBackButton" data-sentry-source-file="Mfa.tsx" />
        <ProviderLogo src={payrollProviderToLocalLogosMap[payrollProvider.id].largeLogo} alt="" data-sentry-element="ProviderLogo" data-sentry-source-file="Mfa.tsx" />
        <Header data-sentry-element="Header" data-sentry-source-file="Mfa.tsx">{headerText}</Header>
        {mfaReasonText && <WarningMessage>
            {mfaReasonText}
            {payrollProvider.id === 'paycom' ? <b>Please wait up to 5 minutes after submission.</b> : ''}
          </WarningMessage>}
        {/* Even if we don't show the additional MFA, we still want to show wait up to 5 minutes */}
        {!mfaReasonText && payrollProvider.id === 'paycom' ? <WarningMessage>
            <b>Please wait up to 5 minutes after submission.</b>
          </WarningMessage> : null}
        {mfaReasonText && <MfaText>{mfaText}</MfaText>}
        <MultiValueForm formKey={formKey} onSubmit={onContinue} fieldNames={['mfaCode']} render={({
        mfaCode
      }) => <>
              <MainContainer>
                <Input id="mfaCode" value={mfaCode.value} onChange={event => mfaCode.onChange(event.target.value)} invalid={mfaCode.invalid} label={mfaType === 'question' ? 'Answer' : 'Code'} autoFocus />
                {payrollProvider.id === 'quickbooks' && <SignInDisclaimer>
                    Keep the window open while we sign into your Quickbooks
                    account. This could take a few minutes.
                  </SignInDisclaimer>}
              </MainContainer>
              <LoaderButton color={payrollProvider.primaryColor} isLoading={loading} disabled={loading || disableSubmit} type="submit">
                Continue
              </LoaderButton>
            </>} data-sentry-element="MultiValueForm" data-sentry-source-file="Mfa.tsx" />
      </Container>
    </AuthFallbackBoundary>;
};
export default withAuthorizeContext(Mfa);