import React, { useState } from 'react';
import { validate } from 'email-validator';
import { authorize } from 'services/auth';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import payrollProviderToLocalLogosMap from 'constants/payrollProviderToLocalLogosMap';
import Container from 'components/Container/Container';
import ProviderLogo from 'components/Logo/ProviderLogo';
import SignInGuide from 'pages/Authorize/SignIn/SignInGuide';
import RegisterManualAdapter from './RegisterManualAdapter';
import LoadingSpinner from '../LoadingSpinner';
import ManualInstruction from './ManualInstruction';
import BetaTag from '../../../components/VersionTag/BetaTag';
import { isImplementationInBeta } from '../../../utils/providers';
import { GoBackButton } from 'components/Button/GoBackButton';
import { useFlag } from '@unleash/proxy-client-react';
import SignInManualAdapter from './SignInManualAdapter';
import SignInManualInstruction from './SignInManualInstruction';

/**
 * The component that handles assisted benefits flow and acf flow
 */
const ManualSignIn = ({
  client,
  clientName,
  connectionId,
  payrollProvider,
  providersConfig,
  providerImplementation,
  contactEmail: contactEmailInContext,
  companyName: companyNameInContext,
  handleAuthorizeResponse,
  currentStep,
  clientId,
  redirectUri,
  products,
  sessionKey,
  currentBrowserSessionKey,
  state,
  category,
  sandbox,
  prevStep,
  appType,
  usingAcfFallback,
  setError,
  handleStateRedirect,
  implementationHasAssistedBenefits
}: AuthorizeContextValue) => {
  const [isDoneSignIn, setIsDoneSignIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contactEmail, setContactEmail] = useState(contactEmailInContext);
  const [companyName, setCompanyName] = useState(companyNameInContext);
  const useNewDesign = useFlag('finchConnectCleanup');
  if (!client) return <Container newContainerDesign={useNewDesign}>
        {!client && <LoadingSpinner />}
      </Container>;
  if (!payrollProvider) throw new Error('no payroll provider');
  if (!providersConfig) throw new Error('no providers config');
  if (!providerImplementation) throw new Error('no provider implementation');
  if (!products) throw new Error('no products');
  const onNextStep = (fieldToValueMap: Record<string, string>) => {
    const {
      contactEmail,
      companyName
    } = fieldToValueMap;
    if (contactEmail) {
      if (!validate(contactEmail)) {
        setError({
          message: 'The contact email provided is not valid.',
          omitSessionKey: true
        });
        setLoading(false);
        return;
      }
      setContactEmail(contactEmail);
    }
    if (companyName) setCompanyName(companyName);
    setIsDoneSignIn(true);
  };
  const onSubmit = async () => {
    setLoading(true);
    setError(null);
    if (!contactEmail || !companyName) {
      setError({
        message: 'The contact email or company name is not provided.',
        omitSessionKey: true
      });
      setLoading(false);
      return;
    }
    try {
      const authorizeResponse = await authorize({
        clientId,
        connectionId,
        clientName,
        redirectUri,
        products,
        sessionKey,
        currentBrowserSessionKey,
        state,
        step: currentStep,
        category,
        contactEmail,
        companyName,
        payrollProviderId: payrollProvider.id,
        providerImplementationId: providerImplementation.id,
        sandbox,
        isManualAuth: currentStep !== 'ASSISTED_BENEFITS',
        appType,
        acfFallbackEligible: usingAcfFallback,
        implementationHasAssistedBenefits
      });
      handleAuthorizeResponse(authorizeResponse);
    } catch (err: any) {
      setError({
        message: err.message || 'Unexpected error has occurred. Please try again.'
      });
    }
    setLoading(false);
  };
  const onPreviousStep = () => {
    setIsDoneSignIn(false);
    setCompanyName(companyNameInContext);
    setContactEmail(contactEmailInContext);
  };

  // if there's contact email and company name in context
  // then we can go to next step
  if (payrollProvider.id === 'xero_us' || isDoneSignIn || contactEmailInContext && companyNameInContext) {
    if (useNewDesign) {
      return <SignInManualInstruction client={client} payrollProvider={payrollProvider} onSubmit={onSubmit} onPrevStep={onPreviousStep} contactEmailInContext={contactEmailInContext} companyNameInContext={companyNameInContext} handleStateRedirect={handleStateRedirect} />;
    }
    return <ManualInstruction client={client} payrollProvider={payrollProvider} onSubmit={onSubmit} onPrevStep={onPreviousStep} contactEmailInContext={contactEmailInContext} companyNameInContext={companyNameInContext} handleStateRedirect={handleStateRedirect} />;
  }
  const adapterProps = {
    onNextStep: onNextStep,
    contactEmailInContext: contactEmailInContext,
    companyNameInContext: companyNameInContext,
    isLoading: loading
  };
  return <>
      {providersConfig.length > 1 && currentStep !== 'ASSISTED_BENEFITS' && <GoBackButton onClick={() => prevStep()} />}
      {isImplementationInBeta(providerImplementation) && <BetaTag />}
      <Container newContainerDesign={useNewDesign} data-sentry-element="Container" data-sentry-source-file="ManualSignIn.tsx">
        <ProviderLogo src={payrollProviderToLocalLogosMap[payrollProvider.id].largeLogo} alt="" data-sentry-element="ProviderLogo" data-sentry-source-file="ManualSignIn.tsx" />
        <SignInGuide client={client} products={products} provider={payrollProvider} currentStep={currentStep} enabled={currentStep === 'ASSISTED_BENEFITS'} data-sentry-element="SignInGuide" data-sentry-source-file="ManualSignIn.tsx">
          {useNewDesign ? <SignInManualAdapter {...adapterProps} provider={payrollProvider} /> : <RegisterManualAdapter {...adapterProps} />}
        </SignInGuide>
      </Container>
    </>;
};
export default withAuthorizeContext(ManualSignIn);