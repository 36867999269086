import React from 'react';
import { Response as ClientResponse, ProviderConfig } from '@finch-api/common/dist/internal/connect/validate';
import payrollProviderToLocalLogosMap from 'constants/payrollProviderToLocalLogosMap';
import styled from 'styled-components';
import Container from 'components/Container/Container';
import Header from 'components/Header/Header';
import LoaderButton from 'components/Button/LoaderButton';
import ProviderLogo from 'components/Logo/ProviderLogo';
import { updatedInstructions } from './instructions';
import { StateRedirect } from '../types';
import { GoBackButton } from 'components/Button/GoBackButton';
import SignInBase from '../SignIn/SignInBase';
import MainContainer from '../SignIn/SignInMainContainer';
import InstructionsCheckbox from 'components/Input/InstructionsCheckboxInput';
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SignInManualInstruction = ({
  client,
  payrollProvider,
  contactEmailInContext,
  companyNameInContext,
  onSubmit,
  onPrevStep,
  handleStateRedirect
}: {
  client: ClientResponse;
  payrollProvider: ProviderConfig;
  contactEmailInContext: string | undefined;
  companyNameInContext: string | undefined;
  onSubmit: () => Promise<void>;
  onPrevStep: () => void;
  handleStateRedirect: (redirect: StateRedirect) => void;
}) => {
  const isXeroUSProvider = payrollProvider.id === 'xero_us';
  const instructions = updatedInstructions(payrollProvider, client);
  const onRedirect = () => {
    handleStateRedirect({
      next: 'SIGN_IN',
      nextContext: {
        redirect: true,
        payrollProviderId: 'gusto'
      }
    });
  };
  return <>
      {!(companyNameInContext && contactEmailInContext) && <GoBackButton onClick={onPrevStep} />}
      <Container newContainerDesign data-sentry-element="Container" data-sentry-source-file="SignInManualInstruction.tsx">
        <HeaderWrapper data-sentry-element="HeaderWrapper" data-sentry-source-file="SignInManualInstruction.tsx">
          <ProviderLogo src={payrollProviderToLocalLogosMap[payrollProvider.id].largeLogo} alt="" data-sentry-element="ProviderLogo" data-sentry-source-file="SignInManualInstruction.tsx" />
          <Header data-sentry-element="Header" data-sentry-source-file="SignInManualInstruction.tsx">
            Connect your
            {` ${payrollProvider.displayName} `}
            account
          </Header>
        </HeaderWrapper>
        <SignInBase spacing="md" instructions={instructions} divider fieldNames={['instructionCheck']} onSubmit={isXeroUSProvider ? onRedirect : onSubmit} render={({
        instructionCheck
      }) => {
        return <>
                <MainContainer useNewDesign>
                  {!isXeroUSProvider && <InstructionsCheckbox checked={instructionCheck.value} onChange={checked => instructionCheck.onChange(checked)} />}
                </MainContainer>
                <LoaderButton type="submit" disabled={!instructionCheck.value}>
                  {isXeroUSProvider ? 'Redirect' : 'Complete'}
                </LoaderButton>
              </>;
      }} data-sentry-element="SignInBase" data-sentry-source-file="SignInManualInstruction.tsx" />
      </Container>
    </>;
};
export default SignInManualInstruction;